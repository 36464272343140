import { httpAgent, httpsAgent } from '@doda/common/src/libs/axios/setting';
import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import qs from 'qs';

export const isServer = typeof window === 'undefined';

// jsonp用タイプ
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  callbackParamName?: string;
}

interface CustomAxiosInstance extends AxiosInstance {
  (config: CustomAxiosRequestConfig): AxiosPromise;
}

export const TIMEOUT = Number.isNaN(Number(process.env.REQUEST_TIMEOUT))
  ? 5000
  : Number(process.env.REQUEST_TIMEOUT);

/**
 * リクエストヘッダー作成（サーバーサイド用）
 * @param {IncomingHttpHeaders | undefined} headers
 * @returns {IncomingHttpHeaders | undefined} ヘッダー
 */
export const createHeader = (
  headers?: AxiosRequestHeaders
): AxiosRequestHeaders | undefined => {
  if (!headers) return;
  return {
    host: headers.host,
    'x-forwarded-for': Object.prototype.hasOwnProperty.call(
      headers,
      'x-forwarded-for'
    )
      ? headers['x-forwarded-for']
      : '',
    cookie: Object.prototype.hasOwnProperty.call(headers, 'cookie')
      ? headers.cookie
      : '',
    authorization: Object.prototype.hasOwnProperty.call(
      headers,
      'authorization'
    )
      ? headers.authorization
      : '',
    'Content-Type': headers?.['Content-Type'] || '',
  } as unknown as AxiosRequestHeaders;
};

/**
 * HTTP ステータス
 */
export const HttpStatus = {
  Success: [200, 201, 204],
} as const;

/**
 * エンドポイントによって接続先を変える
 * @param req
 * @returns
 */
export const getBaseURL = (req: CustomAxiosRequestConfig) => {
  if (req.url?.indexOf('/api/') === 0) {
    return process.env.BASE_HYDROGEN_URL;
  }
  return process.env.BASE_URL;
};

const paramsSerializer = (params: any) =>
  qs.stringify(params, { arrayFormat: 'repeat' });

/**
 * アプリケーション用のカスタムAxios
 * @remarks
 * オプション：
 *   withCredentials: true - リクエストクッキーをフォーワードさせる（defaultではfalse）
 */
export const appAxios: CustomAxiosInstance = axios.create({
  withCredentials: true, // Forward Cookies
  paramsSerializer,
  httpAgent,
  httpsAgent,
});

// リクエスト割込み処理
appAxios.interceptors.request.use((request) => {
  // api-clientはhydrogenURLのみ
  request.baseURL = getBaseURL(request);
  if (isServer) {
    // SSR時のリクエストヘッダー設定およびプロキシー設定
    request.headers = createHeader(request.headers) || request.headers;
    request.proxy = false;
    request.timeout = TIMEOUT;
  }

  // 共通処理
  return request;
});

// レスポンス割込み処理
appAxios.interceptors.response.use((response) => {
  // 共通処理
  if (HttpStatus.Success.some((v) => v === response.status)) {
    return response;
  }

  return Promise.reject(response);
});
